@media only screen and (max-width: 992px) {
  .navbar {
    height: auto;
    border: none;
    position: fixed;
    width: 100%;
    z-index: 999999;
    margin-top: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
  }

  .landing-heading h1 {
    font-size: 40px;
  }

  .navbar-toggler {
    padding: 0;
    z-index: 9;
    border: none;
  }

  .navbar-toggler:focus {
    border: none;
    box-shadow: none;
  }

  .navbar-toggler-icon {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: black;
  }

  .iconone,
  .icontwo,
  .togglefouricon {
    transition: 0.4s ease all;
  }

  .icontwo,
  .iconfour {
    margin-left: 6px;
  }

  .navul {
    display: block;
    margin-top: 10%;
    padding-left: 0;
  }

  .navul li {
    padding-left: 0 !important;
  }

  .navul li:nth-child(5) {
    border: none;
  }

  .navul li:nth-child(1) {
    padding-left: 0px !important;
  }

  .moblogbrand {
    width: 35%;
    margin-top: 5%;
  }

  .middlenav {
    position: initial;
    display: unset;
    transform: translate(0, 0);
    height: auto;
  }

  .moblogo {
    display: block;
  }

  .mobnavlogo {
    display: block;
    margin-top: 14%;
    opacity: 0.1;
  }

  .navbar-collapse .nav-item {
    padding-top: 10px;
    padding-bottom: 10px;
    animation-name: fadeInLeft;
    border-bottom: 1px solid grey;
  }

  .navbar-collapse.collapsing {
    left: -100%;
    transition: height 0s ease;
  }

  .navbar-collapse.collapsing .navoverlay {
    left: -100%;
    transition: height 0s ease;
  }

  .navbar-collapse.show,
  .navbar-collapse.show .navoverlay {
    background: #ED4723;
    left: 0;
    transition: left 300ms ease-in;
  }

  .navbar-toggler.collapsed~.navbar-collapse {
    transition: left 500ms ease-in;
  }

  .nav-item {
    padding-top: 10px;
    padding-bottom: 10px;
    animation-name: fadeInLeft;
    border-bottom: 1px solid grey;
  }

  .toggletwoicon {
    height: 13px;
  }

  .iconone,
  .icontwo,
  .toggletwoicon,
  .togglefouricon {
    transition: 0.4s ease all;
  }

  .navbar-collapse {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 7;
    background-color: black;
  }

  .navbar-collapse .nav-link {
    font-size: 1.5rem;
  }

  .contact-usbtn {
    margin-left: 0 !important;
  }

  .moblogo2 {
    width: 100%;
    opacity: 0.1
  }

  .navbar-collapse .nav-item {
    padding-top: 10px;
    padding-bottom: 10px;
    animation-name: fadeInLeft;
    border-bottom: 1px solid grey;
  }

  .navbar-collapse.collapsing {
    left: -100%;
    transition: height 0s ease;
  }

  .navbar-collapse.collapsing .navoverlay {
    left: -100%;
    transition: height 0s ease;
  }

  .navbar-collapse.show,
  .navbar-collapse.show .navoverlay {
    left: 0;
    transition: left 300ms ease-in-out;
  }

  .navbar-toggler.collapsed~.navbar-collapse {
    transition: left 500ms ease-in-out;
  }

  .nav-tabs .nav-link {
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: 15px !important;
  }

  .mobilenav-img {
    display: block;
  }

  .navbar-brand {
    z-index: 7;
    width: 35%;
  }

  .logomain {
    width: 80%;
  }

  #about .col-md-5 {
    align-items: center;
  }

  .projectname {
    margin-top: 0;
  }

  figure {
    height: auto;
  }

  .getintouch h1 {
    font-size: 30px !important;
  }

  .getintouch img {
    width: 30px;
  }

  .footer h4 {
    font-size: 20px;
    padding-bottom: 10px;
  }

  #Landingsection {
    height: auto;
  }

  #portfolio {
    margin-top: 100px;
  }

  .portfolio-heading h1 {
    font-size: 50px;
  }

  .pspan2 {
    font-size: 30px;
  }

  #teamsection h2 {
    font-size: 20px;
  }

  .client-heading h1 {
    font-size: 50px;
  }

  .services-parent6 {
    bottom: 1%;
  }

  .services-parent {
    width: 100%;
  }

  .services-parent2, .services-parent4, .services-parent6 {
    right: 0;
    left: 100px;
  }

  .services-parent4 {
    top: 55%;
  }

  .services-parent5 {
    top: 72%;
  }

  .zigzag img {
    width: 100%;
  }

  .moblogbrand {
    display: block;
    margin-top: 0;
  }

  .middlenav {
    position: initial;
    display: unset;
    transform: translate(0, 0);
    height: auto;
  }

  .navul {
    display: block;
    margin-top: 10%;
    padding-left: 0;
  }

  .navul li {
    padding-left: 0px !important;
    border-bottom: 1px solid #ffffff3b;
  }

  .navbar-collapse .nav-link {
    font-size: 1.5rem;
    margin-left: 23px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: white;
  }

  .endnav a {
    font-size: 1.5rem !important;
    color: black !important;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 768px) {

  .portfolio-heading {
    padding-top: 0px;
  }

  .portfolio-heading h1 {
    font-size: 35px;
  }

  .landingcontent-parent {
    padding-right: 0;
  }

  .portfolio-heading p {
    padding-top: 0;
  }

  .ptop100 {
    padding-top: 0;
  }

  figure {
    height: auto;
  }

  .ptop50 {
    padding-top: 20px;
  }


  .team-content {
    position: initial;
  }

  .team-content h2 {
    font-size: 20px;
    padding-top: 30px;
  }

  .team-title p {
    font-size: 12px;
  }

  .client-heading h1 {
    font-size: 35px;
    padding-top: 50px;
    padding-bottom: 30px;
    text-align: center;
  }

  .clients {
    margin-top: 30px;
  }

  .phone-heading h1, .about-usheading {
    font-size: 35px;
  }

  .emailform input {
    width: 60%;
  }

  footer h4 {
    padding-top: 40px;
  }

  .aboutus-heading h1 {
    font-size: 35px;
  }

  .service-maincontent p {
    padding-right: 0%;
  }

  /* .aboutus-heading {
    display: none;
  } */

  .aboutus-mobile {
    display: block;
  }

  .aboutuscontent-parent {
    padding-top: 30px;
  }

  .overlay-cardsimg h2 {
    font-size: 20px;
  }

  .cards-para {
    bottom: 0px;
  }

  .service-heading h2 {
    font-size: 20px;
  }

  .product-contentparent p {
    padding-right: 0;
  }

  .overlay-cardsimg {
    border-radius: 0.5rem;
  }

  .cardsimg {
    margin-bottom: 20px;
  }

  .zigzag img {
    width: 100%;
    margin-top: 0;
  }

  .product-heading {
    display: none;
  }

  .product-mobile {
    display: block;
    padding-top: 0 !important;
  }

  .projects-heading, .services-heading, .contactus-heading {
    display: none;
  }

  .projects-headingmobile, .services-mobile, .contactus-headingmobile {
    display: block;
  }

  .projects-headingmobile {
    position: absolute;
    bottom: 20px;
  }

  .projects-headingmobile h1, .services-mobile h1, .contactus-headingmobile h1 {
    margin-bottom: 0;
    line-height: 1;
    font-size: 35px;
  }

  .contact-content {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .servicebottom-content, .doit-heading {
    padding-top: 50px;
  }

  .h-80 {
    height: 60vh;
  }

  .portfolio-heading h1 {
    padding-top: 30px;
  }

  #allProducts {
    padding-top: 40px;
  }

  .product-mobile h1 {
    font-size: 35px;
  }

  .services-parent {
    width: 85%;
  }

  .service-content p {
    display: none;
  }

  .service-img {
    width: 50%;
  }

  .zigzag {
    padding-top: 50px;
  }

  .services-parent2 {
    top: 18%;
  }

  .services-parent3 {
    top: 35%;
  }

  .services-parent4 {
    top: 52%;
  }

  .services-parent5 {
    top: 70%;
  }

  .services-parent6 {
    bottom: 5%;
  }

  .footer h4 {
    padding-top: 40px;
  }

  .navbar-toggler {
    border: 0;
  }

  .logomain {
    width: 55%;
  }

  .aboutlandingrow {
    display: none;
  }

  .landingrowmobie {
    display: block;
    position: absolute;
    bottom: 20px;
  }

  .about-usheading {
    color: white;
  }

  .pb100 {
    padding-bottom: 50px !important;
  }


  .landingcontent-parent {
    padding-top: 50px;
  }

  .about-img {
    margin-top: 40px;
  }

  .moblogbrand {
    width: 35%;
    margin-top: 0;
  }

  .moblogo {
    display: block;
    width: 55%;
    margin-top: 20px;
  }

  .contactbtn {
    background: white;
    color: black;
  }

  .navbar {
    position: fixed;
    width: 100%;
    z-index: 999999;
    margin-top: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background: white;
  }


  .glow-text-loading {
    font-size: 50px;
  }

  #landingsection {
    height: 50vh;
    margin-top: 0;
  }

  .readmorebtn {
    margin-left: 12px;
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .mtop10 {
    padding-left: 15px;
    padding-right: 15px;
  }

  figure:hover+span {
    bottom: 0px;
  }

  .projectname span {
    font-size: 18px;
  }

  .footerlogo img {
    width: 50%;
    margin-top: 10%;
  }

  .footerlogo p {
    margin-bottom: 50px;
  }

  .getintouch h1 {
    font-size: 30px !important;
  }

  .getintouch img {
    width: 30px;
  }

  #boxContainerParent {
    transform: translate(0, 50px) !important;
  }

  .pin-spacer {
    height: 250px !important;
    padding: 0px 0px 0px !important;
  }

  .revolvingcircle img {
    width: 100px;
  }

  .revolveicon {
    font-size: 80px;
  }

  .revolvingcircle {
    margin-top: 50px;
  }

  .underline-svg {
    width: 90%;
  }

  .saima-img {
    margin-top: 50px;
  }

  .aboutbtnrow {
    padding-bottom: 15%;
  }

  .submitbtnemail {
    margin-left: 15px;
    margin-top: 60px;
  }

  .lastrow {
    text-align: center;
  }

  .footer-rightcontent {
    justify-content: center;
  }

  figure:hover .testbtn {
    bottom: 40%;
  }

  .aboutusimg {
    margin-left: auto;
    display: flex;
  }

  .podcast-message {
    margin-left: initial;
    width: 50%;
  }

  .glow-text {
    font-size: 80px;
  }

  .podcast-leftparentbox {
    margin-left: 2%;
  }

  .podcast-detail h2 {
    line-height: 20px;
    font-size: 20px;
  }

  .podcast-detail p {
    font-size: 14px;
  }

  .podcast-rightparentbox {
    margin-left: 10%;
  }

  .moblogbrand {
    display: block;
  }

  .waves {
    right: -10px;
  }

  .mic {
    width: 25%;
    margin-left: -15px;
  }

  .mobilenav-img {
    display: block;
  }

  .mic-mob {
    width: 80%;
    transform: rotate(45deg);
    opacity: 0.1;
  }

  .navbar-toggler-icon {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: black;
  }

  .iconone,
  .icontwo,
  .togglefouricon {
    transition: 0.4s ease all;
  }

  .icontwo,
  .iconfour {
    margin-left: 6px;
  }

  .navul {
    display: block;
    margin-top: 10%;
    padding-left: 0;
  }

  .navul li {
    padding-left: 0 !important;
    border-bottom: 1px solid #ffffff3b;
  }

  .mobnavlogo {
    display: block;
    margin-top: 14%;
    opacity: 0.1;
  }

  .navbar-collapse .nav-item {
    padding-top: 10px;
    padding-bottom: 10px;
    animation-name: fadeInLeft;
    border-bottom: 1px solid grey;
  }

  .navbar-collapse.collapsing {
    left: -100%;
    transition: height 0s ease;
  }

  .navbar-collapse.collapsing .navoverlay {
    left: -100%;
    transition: height 0s ease;
  }

  .navbar-collapse.show,
  .navbar-collapse.show .navoverlay {
    background: #ED4723;
    left: 0;
    transition: left 300ms ease-in;
  }

  .navbar-toggler.collapsed~.navbar-collapse {
    transition: left 500ms ease-in;
  }

  .nav-item {
    padding-top: 10px;
    padding-bottom: 10px;
    animation-name: fadeInLeft;
    border-bottom: 1px solid grey;
  }

  .toggletwoicon {
    height: 13px;
  }

  .iconone,
  .icontwo,
  .toggletwoicon,
  .togglefouricon {
    transition: 0.4s ease all;
  }

  .navbar-collapse {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 8;
    background-color: black;
  }

  .navbar-collapse .nav-link {
    font-size: 1.5rem;
    margin-left: 23px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: white;
  }

  .endnav a {
    font-size: 1.5rem !important;
    color: black !important;
    margin-left: 0 !important;
  }

  .vissionrow {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .missionrow {
    padding-bottom: 0 !important;
  }

  /* 
  .team-heading {
    padding-top: 0 !important;
  } */
  .landingrow {
    padding-bottom: 0;
  }

  .vission-heading {
    padding-top: 50px;
  }

  .contact-usbtn {
    margin-left: 0 !important;
  }

  .moblogo2 {
    width: 100%;
    opacity: 0.1
  }

  .navbar-collapse .nav-item {
    padding-top: 10px;
    padding-bottom: 10px;
    animation-name: fadeInLeft;
    border-bottom: 1px solid grey;
  }

  .navbar-collapse.collapsing {
    left: -100%;
    transition: height 0s ease;
  }

  .navbar-collapse.collapsing .navoverlay {
    left: -100%;
    transition: height 0s ease;
  }

  .navbar-collapse.show,
  .navbar-collapse.show .navoverlay {
    left: 0;
    transition: left 300ms ease-in-out;
  }

  .navbar-toggler.collapsed~.navbar-collapse {
    transition: left 500ms ease-in-out;
  }

  .nav-tabs .nav-link {
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: 15px !important;
  }

  .back-to-top {
    width: 50px;
    height: 50px;
  }

  .marquee span,
  .commnity {
    font-size: 80px;
  }

  .footer {
    padding: 10px;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .row-mobile {
    display: flex;
  }

  .row-desktop {
    display: none;
  }

  .footerlogo-mob {
    width: 30%;
  }

  .footer h4 {
    font-size: 20px;
  }

  .gridview:nth-child(2) {
    margin-top: 20px;
  }

  .gridview2 {
    margin-top: 20px;
  }

  .getintouch {
    position: relative;
    margin-bottom: 50px;
  }

  .services-parent2, .services-parent4, .services-parent6 {
    right: 0;
    left: 40px;
  }

  .footer-rightcontent ul {
    padding-left: 0;
  }

  .footer-rightcontent ul li:nth-child(1) {
    padding-left: 0;
  }

  #services, #projects, #products, #about-us, #Landingsection {
    height: 60vh;
  }

  .service-content-main {
    bottom: 0;
  }

  .ourproduct-heading {
    padding-top: 50px;
  }

  #contact {
    margin-top: 40px;
  }

  .landing-heading h1 {
    font-size: 35px;
  }

  .topcontent, .contactus-headingmobile {
    position: absolute;
    bottom: 20px;
  }

  .contact-container {
    position: relative;
    overflow: hidden;
  }
  .landingcontent-parent{
    display: none;
  }
  .landingcontent-parentmobile{
    display: block;
  }
  #teamsection{
    padding-bottom: 30px;
  }
  #teamsection h1{
    font-size: 35px;
  }
  #client .owl-carousel .owl-stage{
    display: flex;
    align-items: center;
  }
}